* {
  margin: 0;
  padding: 0;
}

html, body {
  margin: 0;
  padding: 0;
  min-height: 100%;
  height: 100%;
}

#root {
  position: relative;
  min-height: 100vh;
}

.react-apple-signin-auth-btn {
  border-radius: 7px;
  width: 100%;
}