.facebook-button {
  height: 44px;
  width: 370px;
  border-radius: 6px;
  border: none;
  background: #0079fb;
  cursor: pointer;
  outline: none;
  color: #ffffff;
  font-family: "Avenir Next";
  font-size: 20px;
  letter-spacing: -0.5px;
  line-height: 27px;
  text-align: center;
  position: relative;
}

.facebook-button::after {
  content: 'Facebook';
  font-weight: bold;
  display: inline-block;
}

.facebook-button::before {
  content: '';
  display: inline-block;
  height: 23.85px;
  width: 24px;
  background-image: url('../../assets/svg/facebook-sign-in.svg');
  margin-right: 8px;
  position: relative;
  top: 3px;
  background-size: cover;
}