.modal-wrapper {
  height: 286px;
  width: 442px;
  margin: 100px auto;
  border: none;
  border-radius: 8px;
  /* padding: 20px; */
  box-shadow: 0 0 20px #000000;
  outline: none;
  background-color: #ffffff;
  display: flex;
  flex-direction: column;
  /* justify-content: space-around; */
  align-items: center;
  box-sizing: border-box;
}
