.categories-list {
  transform: translate3d(0px, 0px, 0px);
  transition: transform 0.4s ease 0s;
  width: 990000px;
  text-align: left;
  display: flex;
}

.item {
  background: #ffffff;
  min-width: 131px;
  border-radius: 8px;
  height: 60px;
  padding: 0 14px;
  display: flex !important;
  align-items: center;
  cursor: pointer;
  margin-left: 10px;
  box-sizing: border-box;
  outline: none;
}

.item:first-of-type {
  margin-left: 0;
}

.item:last-of-type {
  margin-left: 250px;
  width: 200px;
  visibility: hidden;
}

.scroll-menu-arrow--disabled {
  visibility: hidden;
}

.active-item {
  background: linear-gradient(23.35deg, #32198C 0%, #8E00E6 100%);
}