@font-face {
  font-family: 'Avenir Next';
  src: url(./assets/fonts/avenirNext/Avenir-Regular.ttf) format('truetype');
}

@font-face {
  font-family: 'Avenir Next';
  font-weight: 600;
  src: url(./assets/fonts/avenirNext/Avenir-Demi.ttf) format('truetype');
}

@font-face {
  font-family: 'Avenir Next';
  font-weight: bold;
  src: url(./assets/fonts/avenirNext/Avenir-Bold.ttf) format('truetype');
}

@font-face {
  font-family: 'Avenir Next';
  font-weight: 500;
  src: url(./assets/fonts/avenirNext/Avenir-Medium.ttf) format('truetype');
}

@font-face {
  font-family: Avenir;
  font-weight: 900;
  src: url(./assets/fonts/avenirNext/Avenir-Black.ttf) format('truetype');
}

@font-face {
  font-family: 'Oswald';
  font-style: normal;
  font-weight: 500;
  src: url(./assets/fonts/oswald/Oswald-Medium.ttf) format('truetype');
}
